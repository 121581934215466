<template>
  <div>
    <div class="bg-primary">
      <DaisyNavBar color="primary" class="max-w-screen-2xl mx-auto">
        <template #start>
          <DaisyButton
            title="Click to open Drawer"
            shape="square"
            class="relative transition-all lg:opacity-0 lg:w-0"
            :class="$route.meta.drawerSwitch ? '' : 'opacity-0 w-0'"
            ghost
            @click="() => (drawerOpen = !drawerOpen)"
          >
            <DaisySwap v-model="drawerOpen" effect="rotate">
              <template #on>
                <Icon name="iconamoon:close-bold" size="32px" />
              </template>
              <template #off>
                <Icon name="material-symbols:menu-rounded" size="32px" />
              </template>
            </DaisySwap>
          </DaisyButton>
          <NuxtLink to="/" title="Go back home" class="mx-4">
            <h2
              v-if="association"
              class="text-2xl font-bold tracking-wider truncate max-w-16"
            >
              {{ association.name }}
            </h2>
            <img
              v-else
              src="~/assets/logo/horizontal/white.svg"
              alt="Dienst 3"
              class="h-[32px]"
            />
          </NuxtLink>
        </template>
        <template #end>
          <DaisyDropdown v-if="account" ref="userMenu" class="z-30" end hover>
            <template #label>
              <div class="flex items-center h-full gap-2">
                <span
                  class="hidden sm:inline-block max-w-[256px] truncate text-lg"
                >
                  Hello {{ account?.name }}
                </span>
                <DaisyAvatar :placeholder="initials" round class="h-8 w-8" />
              </div>
            </template>
            <DaisyMenu class="mt-6 p-2 shadow bg-primary rounded-box w-72">
              <DaisyMenuItem name="Change Association" disabled>
                <Icon name="material-symbols:swap-horiz-rounded" size="20px" />
                Change Association
              </DaisyMenuItem>
              <DaisyMenuItem to="/settings" name="Settings">
                <Icon name="material-symbols:manage-accounts" size="20px" />
                Settings
              </DaisyMenuItem>
              <DaisyMenuItem name="Log out" @click="() => logout()">
                <Icon
                  v-if="!loggingOut"
                  name="material-symbols:logout-rounded"
                  size="20px"
                />
                <DaisyLoading v-else type="spinner" size="sm" />
                Log out
              </DaisyMenuItem>
            </DaisyMenu>
          </DaisyDropdown>
          <DaisyButton
            v-else
            type="link"
            :to="authLink"
            title="Log in to your account"
            color="secondary"
          >
            Login
          </DaisyButton>
        </template>
      </DaisyNavBar>
    </div>
    <div class="max-w-screen-2xl mx-auto">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const drawerOpen = ref(false);
const userMenu = ref();
const loggingOut = ref(false);

const accountStore = await useAccountStore();
const associationStore = await useAssociationStore();
const { current: account } = storeToRefs(accountStore);
const { current: association } = storeToRefs(associationStore);

const initials = computed(() =>
  (account.value?.name ?? "")
    .split(" ")
    .map((s) => s[0])
    .join(""),
);

provide("drawerOpen", drawerOpen);

const route = useRoute();
const authLink = computed(() => `/auth/login?=${route.path}`);

const logout = async () => {
  loggingOut.value = true;
  const error = await accountStore.logout();
  if (error) {
    throw error;
  }

  loggingOut.value = false;
};

const router = useRouter();
router.afterEach(() => {
  drawerOpen.value = false;
  const active = useActiveElement();
  let el = active.value;

  while (el) {
    if (el === userMenu.value?.$el) {
      active.value?.blur();
      break;
    }

    el = el.parentElement;
  }
});
</script>
